import { forwardRef } from 'react';
import { type ButtonProps as BaseButtonProps, parseButtonAttrs } from './base-button';
import { cva, type VariantProps } from 'class-variance-authority';
import styles from './outlined-button.module.css';

const buttonStyles = cva('rounded-lg border-2 p-4 font-semibold', {
  variants: {
    background: {
      white: 'bg-white',
      'light-grey': 'bg-light-grey',
      'brand-black': 'border-white bg-brand-black',
      brand: 'bg-brand',
    },
    color: {
      black: 'border-black text-black',
      brand: 'border-brand text-brand',
      white: 'border-white text-white',
    },
  },
  defaultVariants: {
    background: 'white',
    color: 'black',
  },
});

const containerStyles = cva('', {
  variants: {
    block: {
      true: 'block w-full',
    },
    color: {
      black: 'before:border-brand',
      brand: 'before:border-black',
      white: 'before:border-brand',
    },
    fullWidth: {
      true: 'block w-full',
    },
  },
  defaultVariants: {
    color: 'black',
  },
});

interface ButtonProps extends BaseButtonProps, VariantProps<typeof buttonStyles>, VariantProps<typeof containerStyles> {
  className?: string,
  buttonClassName?: string,
}

const OutlinedButton = forwardRef<HTMLButtonElement, ButtonProps>(function ({
  children,
  onClick,
  className = '',
  buttonClassName = '',
  attrs,
  ...props
}, ref) {
  return (
    <button
      ref={ref}
      className={`${styles.outlinedButtonContainer} ${containerStyles({
        ...props,
        className,
      })}`}
      onClick={onClick}
      {...parseButtonAttrs(attrs)}
    >
      <div
        className={`${buttonStyles(props)} ${
          styles.outlinedButton
        } ${buttonClassName}`}
      >
        {children}
      </div>
    </button>
  );
});

OutlinedButton.displayName = 'OutlinedButton';
export {OutlinedButton};